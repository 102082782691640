import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import './Quiz/Quiz.css';
import Header from './Header.jsx';
import Footer from './Footer.jsx';
import data from '../assets/data';
import { useSelector, useDispatch } from 'react-redux';
import { addUser } from '../UserReducer';
import image4 from '../images/call.png';
import congratulations from '../images/congratulations.jpg';
import axios from 'axios';


const Quiz = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [index, setIndex] = useState(0);
    const [result, setResult] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [answerSelected, setAnswerSelected] = useState(false); // Nouvel état pour suivre si une réponse a été sélectionnée

    const users = useSelector((state) => state.users);
    const dispatch = useDispatch(); // Déclarez dispatch en utilisant useDispatch

    useEffect(() => {
        const storedAnswers = sessionStorage.getItem('selectedAnswers');
        if (storedAnswers) {
            setSelectedAnswers(JSON.parse(storedAnswers));
        }
    }, []);

    const checkAnswer = (selectedOptionValue) => {
        setSelectedAnswer(selectedOptionValue);
        setSelectedAnswers(prevAnswers => {
            const updatedAnswers = { ...prevAnswers, [index]: selectedOptionValue };
            // console.log('Selected Answers:', updatedAnswers);
            return updatedAnswers;
        });
        setAnswerSelected(true); // Mettre à jour l'état pour indiquer qu'une réponse a été sélectionnée
    };

    const handleClick = () => {
        console.log('Storing in session:', selectedAnswers);
        sessionStorage.setItem('selectedAnswers', JSON.stringify(selectedAnswers));
    };

    const next = () => {
        handleClick();
        if (index === data.length - 1) {
            console.log('Quiz Completed:', selectedAnswers);
            setResult(true);
            return;
        }
        setIndex(index + 1);
        setSelectedAnswer(null);
        setAnswerSelected(false); // Réinitialiser l'état lorsque vous passez à la question suivante
    };

    const restartQuiz = () => {
        setIndex(0);
        setResult(false);
        setSelectedAnswer(null);
        setSelectedAnswers({});
        sessionStorage.removeItem('selectedAnswers');
    };

    const onSubmit = async (formData) => {
        // Combine les données du formulaire avec les réponses sélectionnées
        const combinedData = {
            ...formData,
            habitation: selectedAnswers[0], // Changez l'index en fonction de votre structure
            toiture: selectedAnswers[1],    // Changez l'index en fonction de votre structure
            chauffage: selectedAnswers[2],
            sous_sole: selectedAnswers[3],
            // Changez l'index en fonction de votre structure
            ...selectedAnswers
        };

        // Stocke les données combinées dans la session
        sessionStorage.setItem('quizData', JSON.stringify(combinedData));

        // Ajoute les données combinées au store Redux
        dispatch(addUser(combinedData));

        // Marque le formulaire comme soumis
        setFormSubmitted(true);

        // Crée une instance de FormData
        let data = new FormData();
        data.append('habitation', combinedData.habitation);
        data.append('toiture', combinedData.toiture);
        data.append('chauffage', combinedData.chauffage);
        data.append('sous_sole', combinedData.sous_sole);
        data.append('prenom', combinedData.prenom);
        data.append('nom', combinedData.nom);
        data.append('adresse', combinedData.adresse);
        data.append('tel', combinedData.tel);
        data.append('email', combinedData.email);
        data.append('agree', combinedData.agree);

        // Configuration de la requête
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://office.renovhabitatconseil.com/api/add',
            headers: { 
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };

        // Envoie les données combinées au serveur
        try {
            const response = await axios.request(config);
            if (response.status === 200) {
                console.log('Données envoyées avec succès à la base de données.');
            } else {
                console.error('Erreur lors de l\'envoi des données à la base de données.');
            }
        } catch (error) {
            console.error('Une erreur s\'est produite lors de l\'envoi des données à la base de données :', error);
        }
    };

    const marginTop = { marginTop: '100px' };

    const colorText = { color : 'black'};

    const gap = { display: 'flex', flexWrap: 'wrap' };

    return (
        <>
            <Header />
            <div className='stepper container'>
                {result ? (
                    formSubmitted ? (
                        <>
                            <h2 style={marginTop} className="text-center">Notre conseiller va vous contacter dans le plus bref délai</h2>
                            <div className="imag">
                                <img src={congratulations} alt="photo" />
                            </div>
                        </>
                    ) : (
                        <>
                            <div style={marginTop} className='d-flex justify-content-center align-items-center contact-2'>
                                <img className="img-5" src={image4} alt="photo" />
                                <form onSubmit={handleSubmit(onSubmit)} className='form-container'>
                                    <h3 style={colorText}>Mes coordoner</h3>
                                    <div className='champs'>
                                        <label>Prénom:</label>
                                        <input
                                            type='text'
                                            className='input'
                                            {...register('prenom', { required: 'Prénom est requis' })}
                                        />
                                        {errors.prenom && <p className='error'>{errors.prenom.message}</p>}
                                    </div>
                                    <div className='champs'>
                                        <label>Nom:</label>
                                        <input
                                            type='text'
                                            className='input'
                                            {...register('nom', { required: 'Nom est requis' })}
                                        />
                                        {errors.nom && <p className='error'>{errors.nom.message}</p>}
                                    </div>
                                    <div className='champs'>
                                        <label>Code postal:</label>
                                        <input
                                            type='number'
                                            className='input'
                                            {...register('adresse', { required: 'Code postal est requis' })}
                                        />
                                        {errors.adresse && <p className='error'>{errors.adresse.message}</p>}
                                    </div>
                                    <div className='champs'>
                                        <label>Numéro de téléphone:</label>
                                        <input
                                            type='tel'
                                            className='input'
                                            {...register('tel', { required: 'Numéro de téléphone est requis' , pattern: {
                                                value: /^[0-9]{10}$/, // Expression régulière pour un numéro de téléphone à 10 chiffres
                                                message: 'Veuillez entrer un numéro de téléphone valide'
                                            }})}
                                        />
                                        {errors.tel && <p className='error'>{errors.tel.message}</p>}
                                    </div>
                                    <div className='champs'>
                                        <label>Email:</label>
                                        <input
                                            type='email'
                                              className='input'
                                            {...register('email', { required: 'Email est requis' })}
                                        />
                                        {errors.email && <p className='error'>{errors.email.message}</p>}
                                    </div>
                                    <div className='champs checkbox-container'>
                                        <input
                                            type='checkbox'
                                            {...register('agree', { required: 'ce champs est requis' })}
                                        />
                                        {errors.agree && <p className='error'>{errors.agree.message}</p>}
                                        <p>Nous respectons vos données. En soumettant ce formulaire, vous acceptez que les informations saisies soient exploitées par nos services dans le seul but de vous recontacter, et ce dans le respect de notre politique de confidentialité.</p>
                                    </div>
                                    <button type='submit'>Soumettre</button>
                                </form>
                            </div> 
                        </>
                    )
                ) : (
                    <div className='quiz'>
                        <h3 className='text-center title'>{data[index].question}</h3>
                        <div className='index text-center'>{index + 1} sur {data.length} Questions</div>
                        <div className='choice-tablette d-flex justify-content-center'>
                            <ul className='choice'>
                                {data[index].options.map((option, optionIndex) => {
                                    const optionValue = option.text;
                                    const isCorrect = optionValue === data[index][`option${data[index].ans}`];
                                    const isSelected = selectedAnswer === optionValue;
                                    let classNames = 'name';
                                    if (isSelected) classNames += ' selected';
                                    if (isCorrect && isSelected) classNames += ' correct';
                                    else if (!isCorrect && isSelected) classNames += ' wrong';
                                    return (
                                        <li
                                            key={optionIndex}
                                            onClick={() => checkAnswer(optionValue)}
                                            className={`d-flex justify-content-center align-items-center mx-4 ${classNames}`}
                                        >
                                            <div className='d-flex flex-column'>
                                                <div className='d-flex justify-content-center mb-4'>
                                                    <img src={option.image} alt={optionValue} className="option-image d-flex justify-content-center" />
                                                </div>
                                                <span className='text-center'>{optionValue}</span>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="suivant d-flex justify-content-center">
                            <button onClick={next} disabled={!answerSelected}>Suivant</button> {/* Désactiver le bouton si aucune réponse n'est sélectionnée */}
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </>
    );
};

export default Quiz;
