import { createSlice } from "@reduxjs/toolkit";
import data from  './assets/data';

const initialState = {
    users: data // Initialiser avec une liste vide pour stocker les utilisateurs
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        addUser: (state, action) => {
            // Logique pour ajouter un utilisateur à la liste des utilisateurs
            console.log(state.users.push(action.payload)); // Supposons que action.payload contient les données de l'utilisateur
        }
    }
});

export const { addUser } = userSlice.actions;
export default userSlice.reducer;
