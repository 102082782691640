import React from 'react';
import './Quiz/Footer.css';

const Footer = () => {
    const border = {borderTop:'2px solid red'}
    return (
        <div className='footer'>
            <a className="d-flex justify-content-center align-items-center" href="">
                <img src="" alt="brand" />
            </a>
            <div className="paragraph d-flex justify-content-center align-items-center">
                <p className='text-center text'>Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page</p>
            </div>
            <div className='d-flex justify-content-center align-items-center'>
                <a href="">
                    <img src="" alt="" />
                </a>
                <a href="">
                    <img src="" alt="" />
                </a>
                <a href="">
                    <img src="" alt="" />
                </a>
                <a href="">
                    <img src="" alt="" />
                </a>
            </div>
        </div>
    )
}

export default Footer;