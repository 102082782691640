import React from 'react';
import './Quiz/Header.css';

const Header = () => {
    return (
        <div className='header'>
            <header className='container d-flex justify-content-between align-items-center'>
                <a href="">Brand</a>
                <nav>
                    <a className="contact" href="">Contactez nous</a>
                </nav>
            </header>
        </div>
    )
}

export default Header;