import img1 from '../images/Home.svg';
import img2 from '../images/appartement.svg';
import img3 from '../images/combles-amenage.svg';
import img4 from '../images/combles-perdus.svg';
import img5 from '../images/toit-terrasse.svg';
import img6 from '../images/cave.svg';
import img7 from '../images/vide_sanitaire.svg';
import img8 from '../images/garage-2.svg';
import img9 from '../images/pas_de_sous-sol.svg';
import img10 from '../images/electricite2.svg';
import img11 from '../images/gaz.svg';
import img12 from '../images/fioul.svg';
import img13 from '../images/pompe.svg';
import img14 from '../images/bois.svg';
import img15 from '../images/charbon.svg';


const data = [
    {
      question: "J' habite dans ?",
      options: [
        { text: "Une Maison", image: img1 },
        { text: "Un appartement", image: img2  },
      ],
    },
    {
      question: "Mon type de toiture",
      options: [
        { text: "Combles perdus", image: img3 },
        { text: "Combles aménagés",  image: img4 },
        { text: "Toiture terrasse", image: img5 },
      ],
    },
    {
      question: "Mon sous-sol non-chauffé",
      options: [
        { text: "Cave", image: img6 },
        { text: "Vide Sanitaire",  image: img7 },
        { text: "Garage", image: img8 },
        { text: "Pas de sous-sol ou sous-sol chaufé", image: img9 },
      ],
    },
    {
      question: "Comment vous chauffez-vous ?",
      options: [
        { text: "Gaze", image: img10  },
        { text: "Electricite",  image: img11  },
        { text: "Fioul",  image: img12  },
        { text: "Pompe à chaleur",  image: img13  },
        { text: "Bois",  image: img14  },
        { text: "Charbon",  image: img15  },
      ],
    },
  ];
  
  export default data;
  