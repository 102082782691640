import React from 'react';
import Quiz from './Components/Quiz';

const App = () => {
    return (
        <>
           <Quiz />
        </>
    )
}

export default App;